.custom-accordion {
  border: 1px solid $lightgrey !important;
  padding: 10px;
}

.custom-accordion__title {
  color: $darkgrey;
  display: flex;
  align-items: center;
  @include breakpoint($mobile-lg) {
    color: $black;
  }
  h2 {
    margin-left: 15px;
    font-size: 12px;
  }

  .spr_icon {
    display: flex;
    color: $darkgrey;
  }
}

.custom-accordion__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .custom-accordion__content--labels {
    display: flex;
    flex-direction: column;
    color: $black;
    font-size: 12px !important;

    h2 {
      font-weight: normal;
    }

    h2:first-child {
      margin-bottom: 3px;
    }
  }

  .btn {
    color: $mediumgrey;
    border-color: $mediumgrey;
  }
}
