@import '../../../styles/local-includes.scss';

.sharetooltip.spr_tooltip.align-bottom .spr_tooltip__content {
  width: auto;
  padding: 15px;
  top: 55px;
}

.sharetooltip__content button {
  width: 100%;
}

.sharetooltip__content .spr_copytoclipboard__message {
  z-index: 100;
  font-size: 14px;
  right: -25px;
}

.spr_copytoclipboard:not(.is-copying) .spr_copytoclipboard__message {
  display: none !important;
}

.sharetooltip__title {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  .spr_icon,
  .spr_icon svg {
    color: $black;
  }
}

.sharetooltip__shareicons {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 15px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% + 30px);
    height: 1px;
    background-color: $lightgrey;
  }

  li {
    padding: 4px;
    border-radius: 50%;
    margin: 0 5px;

    &.icon--youtube {
      background-color: #c4302b;
    }
    &.icon--facebook {
      background-color: #1877f2;
    }
    &.icon--instagram {
      background-color: #3f729b;
    }
    &.icon--twitter {
      background-color: #00acee;
    }
  }

  .spr_icon,
  .spr_icon svg {
    color: $white;
    display: block;
  }
}

.sharetooltip__wrapper {
  position: relative;
}

.sharetooltip__copyconfirmation {
  position: absolute;
  padding: 5px;
  background-color: $blue;
  color: $white;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  white-space: nowrap;
}
