@import '../../../styles/local-includes.scss';

.tool-page {
  margin: 2em auto;
  max-width: 1440px;
  padding: 0 2em;

  hr {
    margin-top: 4em;
  }

  .tool-header {
    background-color: #00acee;
    border-radius: 1em;
    background: linear-gradient(13.96deg, #a33ef2 -4.2%, #3c04b3 104.32%);
    color: $white;
    display: flex;
    padding: 3em 1em 0 3em;
    position: relative;

    .tool-left-container {
      display: flex;
      flex-direction: column;

      .tool-imagotype {
        display: flex;
        flex-direction: column;

        .tool-isologo {
          padding: 1em;
          margin-bottom: 1.5em;
          border: 0.2em solid $white;
          border-radius: 1em;
          display: block;
          width: fit-content;
          height: fit-content;

          img {
            width: 2.5em;
          }

          @include breakpoint($mobile-lg) {
            margin: 0 1.5em 0 0;
            padding: 0.9em;
            font-size: 0.9em;
          }

          @include breakpoint($tablet-lg) {
            margin: 0 1.5em 0.5em 0;
          }
        }

        h1 {
          font-size: 4em;
          font-weight: 500;

          @include breakpoint($mobile-lg) {
            font-size: 2.5em;
          }
        }

        @include breakpoint($tablet-lg) {
          flex-direction: row;
          align-items: end;
          margin-bottom: 1em;
        }
      }

      .header-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .tool-description {
          max-width: 37em;

          h4 {
            font-size: 1.34em;
            font-weight: 400;
          }

          @include breakpoint($mobile-lg) {
            width: 90%;
            margin-bottom: 3em;
          }
        }

        .call-to-action-container {
          display: flex;
          flex-direction: column;
          margin-top: 2em;
          margin-bottom: 2em;

          & > span {
            margin-left: 1em;
            font-size: 1.5em;
            font-weight: 500;

            small {
              font-size: 0.8em;
              font-weight: 400;
            }
          }

          small {
            font-size: 1.2em;

            @include breakpoint($mobile-lg) {
              margin-bottom: 1em;
            }
          }

          .spr_mark__btn {
            margin-top: 0.5em;
            font-weight: 500;

            @include breakpoint($mobile-lg) {
              margin: 0;
            }
          }

          @include breakpoint($mobile-lg) {
            align-items: center;
            margin-top: 0;
          }
        }

        .bottom-text {
          margin-bottom: 1.5em;
          font-size: 1.17em;

          .terms-of-service {
            margin-right: 1em;
          }

          span {
            text-align: center;

            @include breakpoint($mobile-lg) {
              margin-bottom: 1em;
            }
          }

          a {
            text-decoration: underline;
            text-underline-offset: 2px;
            color: $white;
          }

          .support-link {
          }

          @include breakpoint($mobile-lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .desktop-tool-showcase {
      max-width: 40%;
      max-height: 100%;
      align-self: flex-end;
      margin-left: auto;

      @include breakpoint($mobile-lg) {
        display: none;
      }
    }

    .mobile-tool-showcase {
      max-width: 90%;
      max-height: 100%;
      display: none;
      align-self: center;
      margin-top: 2em;
      margin-left: 2.5em;

      @include breakpoint($mobile-lg) {
        display: block;
      }
    }

    @include breakpoint($mobile-lg) {
      &:after {
        content: '';
        width: 100%;
        height: 2em;
        position: absolute;
        bottom: -1px;
        left: 0;
        background-color: $white;
        border-radius: 2em 2em 0 0;
      }

      width: auto;
      padding: 3em 2em 5em 2em;
      height: auto;
    }

    @include breakpoint($tablet-lg) {
      border-radius: 0;
    }
  }

  & > .call-to-action-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
      max-width: 30em;
      text-align: center;
      font-size: 1.2em;

      @include breakpoint($mobile-lg) {
        max-width: 80%;
      }
    }

    a {
      color: $black;
    }

    .spr_mark__btn {
      margin: 1em auto;
      margin-top: 2em;
      font-weight: 500;
    }
  }

  article {
    margin-top: 4em;

    span {
      font-size: 1.4em;
      color: rgb(128, 0, 255);

      @include breakpoint($mobile-lg) {
        font-size: 1.2em;
      }
    }

    h2 {
      font-size: 2.3em;
      font-weight: 300;

      @include breakpoint($mobile-lg) {
        font-size: 1.7em;
      }
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2em;

      img {
        height: auto;
        width: 32.5%;

        @include breakpoint($mobile-lg) {
          width: 100%;
        }
      }

      .step-by-step-container {
        overflow: hidden;

        img {
          width: 100%;

          @include breakpoint($mobile-lg) {
            width: auto;
            height: 100%;
          }
        }

        @include breakpoint($mobile-lg) {
          overflow-x: scroll;
          height: 24em;
        }
      }

      @include breakpoint($mobile-lg) {
        flex-direction: column;
        gap: 1em;
      }
    }

    @include breakpoint($mobile-lg) {
      margin: 2em 2em 0;
    }

    @include breakpoint($tablet-lg) {
      margin: 2em;
    }
  }

  .spring_mark__subscription_button.btn {
    font-family: $inter-font-family;
    font-weight: 500;
    font-size: 1.3em;

    width: auto;
    height: 48px;
    margin-right: auto;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 200px;
    border: none;

    &.light {
      background: $white;
      color: $link-blue;
    }

    &.dark {
      background: $link-blue;
      color: $white;
    }

    span {
      white-space: nowrap;
    }
  }

  @include breakpoint($tablet-lg) {
    margin: 0;
    padding: 0;
  }
}
