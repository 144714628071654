@import '../../../../styles/local-includes.scss';

.integration-item {
  margin: 0 12px 16px 0;

  &:last-of-type {
    padding-right: 0;
  }
  @include breakpoint($mobile-lg) {
    margin-right: 12px;
  }

  img {
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;
    width: 220px;
  }
}

.integration-item:first-of-type {
  margin-left: 30px;
}
.integration-item:last-of-type {
  margin-right: 30px;
}
