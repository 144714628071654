.modal__tabs {
  border-bottom: 1px solid $lightergrey;
  width: 100%;
  display: flex;

  li {
    padding: 5px 15px;
    border-bottom: 1px solid $lightergrey;
    margin-bottom: -1px;
    margin-right: 10px;

    &.is-active {
      font-weight: bold;
      border-color: $black;
    }
  }
}
