.samples__mobile__checkout__modal__container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.samples__mobile__checkout__modal__actions {
  font-size: 14px;

  .samples__mobile__checkout__cancel {
    padding-top: 10px;
  }
}
