@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.integrations_page {
  background: transparent;
  min-height: 100vh;
  padding: 0px;
  margin: 0px;
  animation: fadeIn 2s;
}

.spring_store_bold {
  font-weight: 700;
}

.integrations_cards_title {
  font-family: 'DM Sans';
  font-size: 24px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.02em;
  width: 50%;
}

.integrations_cards_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 24px 0 0;
}

.integrations_card_name {
  font-family: 'Inter';
  font-size: 16px;
  color: #08080d;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
}

.integrations_card_img {
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.integrations_cards_subtitle {
  margin-top: 8px;
  font-family: 'Inter';
  color: #404040;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.01em;
}

.integrations_mobile_background {
  display: flex;
  flex-direction: row;
  align-items: end;
  overflow: hidden;
  width: auto;
  padding: 22px;
  height: 376px;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
  background-position: top right !important;
}

.integrations_tablet_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
}

.integrations_item_title_mobile {
  bottom: -20px;
  padding-right: 12px;
  width: 300px !important;
  height: 68px !important;
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 32px;
  line-height: 33.6px;
  letter-spacing: -0.02em;
}

.integrations_item_title_tablet {
  bottom: -20px;
  margin: 0 auto;
  width: 300px !important;
  height: 68px !important;
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 32px;
  line-height: 33.6px;
  letter-spacing: -0.02em;
  overflow: hidden;
}

.integration_card {
  border: solid 1px #d9d9d9;
  border-radius: 8px;
  padding: 12px;
  height: 172px;
  width: 200px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.integrations_green_dot {
  background: #01ba61;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 6px;
}
.integrations_empty_dot {
  border: solid 1px #595959;
  background: #f2f2f2;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 6px;
}
.integration_card:last-child {
  margin-right: 0px !important;
}
@media only screen and (max-width: 1200px) {
  .integration_card {
    width: calc((100% / 4) - 12px);
  }
  .integration_card:nth-child(4n) {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 950px) {
  .integration_card:nth-child(4n) {
    margin-right: 16px;
  }
  .integration_card {
    width: calc((100% / 3) - 11px);
  }
  .integration_card:nth-child(3n) {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 550px) {
  .integration_card:nth-child(3n) {
    margin-right: 16px;
  }
  .integration_card {
    width: calc((100% / 2) - 8px);
  }
  .integration_card:nth-child(2n) {
    margin-right: 0px;
  }
}
.sell_other_platforms_container {
  font-family: DM Sans, sans-serif;
  padding: 24px;
  min-height: 500px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
 & > hgroup {
    border-bottom: 1px solid hsla(0, 0%, 95%, 1);
    padding-bottom: 24px;
    h2 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 14px;
      line-height: 20px;
    }
  }

}

.integrations_page_sections {
  margin: 0 auto;
  max-width: 920px;
}
.sell_other_platforms_content {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 32px;
  align-items: center;
  flex-wrap: wrap;
  figure {
    display: flex;
    flex: 1;
    min-width: 320px;
  }
  img {
    height: 290px;
    width: 100%;
  }
  hgroup {
    margin-bottom: 24px;
    max-width: 420px;
    h2 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    h5 {
      font-size: 14px;
      line-height: 20px;
    }
  }
  a {
    display: flex;
    border: 1px solid hsla(0, 0%, 85%, 1);
    border-radius: 12px;
    color: hsla(240, 24%, 4%, 1);
    font-weight: 500;
    font-size: 0.875rem;
    max-width: 100px;
    height: 42px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}