@mixin mobile-scrolling {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: 12px;
  margin: 0px -24px;
  padding: 0px 24px;
  scroll-padding: 0px 24px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    scroll-snap-align: start;
  }
}
