@import '../../../styles/local-includes.scss';

.add_payment_card_modal__container {
  width: 460px;

  @include breakpoint($mobile-lg) {
    height: 100%;
    width: 100%;
  }

  h1 {
    border-bottom: 1px solid $mediumgrey;
    font-weight: 500;
    margin: 0 -15px 0 -25px;
    padding: 0 15px 1.25em 25px;

    @include breakpoint($mobile-lg) {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
  }

  form {
    align-items: center;
    display: grid;
    row-gap: 1em;

    p {
      font-size: 14px;
      font-weight: 500;
    }

    .add_payment_card_modal__actions {
      display: flex;
      justify-content: flex-end;

      @include breakpoint($mobile-lg) {
        flex-direction: column-reverse;
      }

      .btn {
        font-size: 16px;
        padding: 0 12px;

        @include breakpoint($mobile-lg) {
          padding: 24px 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .btn--pill {
        background-color: $blue;
        border-color: $blue;
        color: $white;

        &.btn--disabled {
          background-color: $lightgrey;
          border-color: $lightgrey;
          color: $darkgrey;
        }
      }

      .btn--transparent {
        border: none;
      }
    }

    .card_payment__extra_info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 12px;
    }

    .stripe_input {
      align-items: center;
      border: 1px solid $lightgrey;
      display: grid;
      height: 42px;
      padding: 0 12px;
    }
  }
}
