@import '../../../styles/local-includes.scss';

.header_copy__prompt {
  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #6a6f72;
  }
}

span {
  &.bold {
    font-weight: 500;
    color: $black;
  }
}

.header-copy__link:visited {
  color: blue;
}
