@import '../../../../styles/local-includes.scss';

.adminbar__url {
  height: 35px;
  background-color: rgba($white, 0.12);
  display: grid;
  grid-template-columns: 1fr minmax(0, auto);
  align-items: center;
  border-radius: 17.5px;
  position: relative;
  padding: 0 3px 0 15px;
  width: 100%;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 40px;
    padding-right: 0;
  }

  .btn {
    margin-left: 5px;
    background-color: $blue;
    height: 30px;
    color: $white;
    border-radius: 15px;
    border: 0;
    padding: 0 15px;
    // media queries
    @include breakpoint($mobile-lg) {
      min-width: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    .spr_icon {
      margin-right: 7px;
      // media queries
      @include breakpoint($mobile-lg) {
        margin-right: 0;
      }
    }
  }
}

.adminbar__confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: $white;
  color: $black;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminbar__urlstring {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: $white;
  text-align: left;
  // media queries
  @include breakpoint($mobile-lg, 'min-width') {
    &:hover {
      text-decoration: underline;
    }
  }
}

.adminbar__shareactions {
  display: flex;
  margin-left: 15px;

  button {
    width: 107px;
    position: relative;
  }
}

.adminbar__shareicons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    padding: 4px;
    border-radius: 50%;
    margin: 0 5px;
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
    justify-content: center;

    &.icon--youtube {
      background-color: #c4302b;
    }
    &.icon--facebook {
      background-color: #1877f2;
    }
    &.icon--instagram {
      background-color: #3f729b;
    }
    &.icon--twitter {
      background-color: #00acee;
    }
  }

  .spr_icon,
  .spr_icon svg {
    color: $white;
    display: block;
  }
}
