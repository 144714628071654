@import '../../styles/local-includes.scss';

$blue: #1954eb;

.terms_of_service__heading {
  margin-top: 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 138.1%;
  margin-bottom: 12px;
}

.terms_of_service__content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.terms_of_service__summary_heading {
  margin-top: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.terms-checkbox {
  padding-top: 36px;
}

terms_of_service__wrapper .spr_input__mark.input__mark,
#terms-read {
  width: 24px !important;
  height: 24px !important;
  top: unset !important;
}

.spr_input__checkbox.is-checked .spr_input__mark {
  background-color: $blue !important;
  border-color: $blue !important;
}

.spr_modal__overlay.modal__overlay {
  pointer-events: none;
}

.modal_template__drag_bar {
  height: 0px;
  margin-bottom: 0;
  margin-top: 16px;
}

.typ--link-quiet {
  color: initial;
  text-decoration: underline;
  cursor: pointer;
}
