@import '../../styles/local-includes.scss';

.savebar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0;
  right: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
  background: $white;
  box-shadow: 0px 11.2421px 16.8632px rgba(38, 39, 40, 0.06),
    0px 1.40527px 4.2158px rgba(38, 39, 40, 0.04),
    0px 0px 0.70263px rgba(38, 39, 40, 0.04);
  // media queries
  @include breakpoint($mobile-lg) {
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    z-index: 99999;
    background: transparent;
    border: 0;
    box-shadow: none;
    width: auto;
    height: 60px;
  }
  .btn--disabled {
    background: $lightgrey;
    color: $darkgrey;
  }
}

.savebar--disabled {
  color: $darkgrey;
  .savebar-actions-save {
    background: $lightgrey;
  }
}

.savebar-actions-discard {
  border-radius: 12px;
  width: 154px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 10px 16px;
  align-items: center;
  line-height: 1;
  display: inline-flex;
}

.savebar-actions-save {
  height: 40px !important;
  min-width: 65px !important;
  border-radius: 12px;
  background-color: #1954eb !important;
  background: #1954eb;
  border: 1px solid #1954eb !important;
  padding: 10px 16px !important;
}
