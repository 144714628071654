@import '../../styles/local-includes.scss';

$blue: #1954eb;

.modal__content {
  background-color: #ffff;
  @include breakpoint($mobile-lg) {
    border-radius: 20px;
  }
  border-radius: 16px;
}

.modal__inner {
  padding: 0px;
  background-color: #ffff;
  border-radius: 16px;
}

.modal_template__container {
  width: 100%;
  z-index: 20;
}

.modal_template__close_icon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal_template__title_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  border-bottom: 2px solid $lightgrey;
}

.modal_template__drag_bar {
  background-color: $lightgrey;
  height: 5px;
  border: none;
  width: 60%;
  margin-top: 32px;
  margin-bottom: 18px;
}

.modal_template__title {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: $black !important;
  margin: 24px;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.left {
    text-align: start;
  }
}
.modal__contentwrapper {
  border-radius: 16px;
}
.modal_template__content {
  margin: 32px 24px 8px 24px;
  border-radius: 16px;
}

.modal_template__actions {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 500;
  padding: 24px;
  @include breakpoint($mobile-lg) {
    flex-direction: column-reverse;
    padding-bottom: 32px;
  }

  .modal_template__actions__cancel_button {
    width: 70px;
    height: 50px;
    background: $white;
    color: #535353;

    @include breakpoint($mobile-lg) {
      width: auto;
    }
  }

  .modal_template__actions__confirm_button {
    min-width: 106px;
    height: 50px;
    background: $blue;
    border-color: $blue;
    border-radius: 200px;
    color: $white;
    &.remove {
      background-color: $white;
      border-color: $mediumgrey;
      color: $red;
    }
    &.disabled {
      background-color: $mediumgrey;
      border-color: $mediumgrey;
    }
    &.wide {
      width: 100%;
    }
    @include breakpoint($mobile-lg) {
      min-width: auto;
    }
  }
  &.hidden {
    display: none;
  }
}
