.toggle-select {
  display: inline-flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  height: 30px;

  &__label {
    flex: 1;
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    // transition: background-color 0.3s, color 0.3s;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.12px;

    &.selected {
      background-color: #000;
      color: #fff;
    }

    &.unselected {
      color: #000;
    }

    &:not(:last-child) {
      border-right: 1px solid #d9d9d9;
    }
  }
}
