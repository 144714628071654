@import '../../styles/local-includes.scss';
.flex-row {
  display: flex;
  flex-direction: row;
}
.black-text {
  color: $black;
}
.app-settings {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  margin: 0 auto;
  max-width: 48rem;
  width: 100%;
  height: 100%;
  h1 {
    font-family: DM Sans, sans-serif;
    font-size: 1.5rem;
  }

  .title {
    @media screen and (max-width: $tablet-md) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
  p {
    color: $darkgrey;
    font-family: Inter, sans-serif;
    font-size: 0.9rem;
    font-weight: normal;
    @media screen and (max-width: $tablet-md) {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: $tablet-md) {
    max-width: 42rem;
  }
  @media screen and (max-width: $tablet-sm) {
    max-width: 39rem;
  }
  @media screen and (max-width: $mobile-lg) {
    max-width: 45rem;
  }
  @media screen and (max-width: $mobile-md) {
    max-width: 32rem;
  }
  @media screen and (max-width: $mobile-sm) {
    max-width: 25rem;
  }
}
