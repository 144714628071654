@import '../../../styles/local-includes.scss';

.purchase_confirmation__heading {
  margin-top: 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 138.1%;
  margin-bottom: 12px;
}

.purchase_confirmation__content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.purchase_confirmation__summary_heading {
  margin-top: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.summary__wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-content: center;
}

.summary__logo {
  margin: 0 15px 15px 0;

  img {
    border-radius: 10px;
    width: 50px;
  }
}

.summary__metawrapper {
  font-size: 14px;
  color: #6a6f72;
}

.summary__title {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $black;
}

.summary__followup_message {
  background-color: #efefef;
  padding: 20px;
  margin-top: 20px;
  color: $black;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

hr {
  border: 1px solid #eaeaea;
}
