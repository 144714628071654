.style-accordion-item {
  border-bottom: none;
  border-color: #fff !important;
}

.layout-item {
  font-family: DM Sans, serif;
  font-size: 0.875rem;
  color: #595959;
}

.theme-and-layout-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.layout-select {
  font-family: 'DM Sans', serif;
  background-color: white;
  font-size: 0.875rem;
  color: #595959;
}
