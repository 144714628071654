@import '../../../styles/local-includes.scss';

.search_container {
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  background: #f7f7f7;

  input {
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 60px 0 8px;
    font-size: 14px;
  }

  .icon {
    padding-left: 10px;
  }
}

.collection__product__load__more {
  display: flex;
  justify-content: center;
  padding: 20px 30px;
  margin-bottom: 30px;
  // media queries
  @include breakpoint($tablet-md) {
    margin-bottom: 0;
  }
}

.product_picker__feed {
  padding: 0;
  .collection__productitem {
    border-bottom: 1px solid $lightgrey;
    margin-bottom: 0;
  }
}

.product_picker__noproducts {
  padding: 32px 0;
  text-align: center;
}
