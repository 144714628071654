@import '../../../styles/local-includes.scss';

.adminbar {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 460px 40px;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  background-color: $darkergrey;
  padding: 0 24px;
  color: $white;
  // media queries
  @include breakpoint($tablet-lg) {
    grid-template-columns: minmax(0, 1fr) 40px;
  }
  @include breakpoint($mobile-lg) {
    padding: 0 15px;
    grid-template-columns: minmax(0, 1fr) 20px;
  }

  .spr_icon {
    color: $white;
  }
}

.spr_iconbutton.iconbutton--reversed {
  display: grid;
  grid-template-areas: 'icon text';
  grid-template-columns: 30px auto;

  .spr_iconbutton__text {
    grid-area: text;
    white-space: nowrap;
  }
  .spr_icon {
    grid-area: icon;
  }
}

.adminbar__info {
  display: flex;
  align-items: center;
  margin-right: 45px;
  // media queries
  @include breakpoint($desktop-sm) {
    margin-right: 5px;
  }
}

.adminbar__title {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 20px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-right: 0;
  }
}

.adminbar__hamburger {
  margin-right: 20px;
  position: relative;
  padding: 13.5px 0;
}

.adminbar__actions {
  display: flex;

  .btn {
    background-color: rgba($white, 0.12);
    border: 0;
    color: $white;
    margin: 0 4px;
    // media queries
    @include breakpoint($mobile-lg) {
      background-color: $white;
      border: 1px solid $lightgrey;
      margin-bottom: 15px;
      color: $black;
    }
  }
}

.adminbar__cta .btn {
  color: $black;
  background-color: $white;
  border-color: $white;
  margin-left: 4px;
  white-space: nowrap;
}

.adminbar__mobileaction {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .spr_icon {
    margin-right: -7px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // media queries
    @include breakpoint($mobile-lg) {
      margin-right: -10px;
      width: 30px;
    }
  }

  .adminbar__actions {
    display: flex;
    flex-direction: column;

    .btn {
      background-color: none;
      padding: 0;
      color: inherit;
      min-width: 0;
      text-align: left;
      font-weight: normal;
      height: auto;
      white-space: nowrap;
      display: block;
      border: 0;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    > li:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  &.is-open .spr_icon {
    background-color: $black;
  }

  &.spr_tooltip.align-bottom .spr_tooltip__content {
    left: auto;
    right: -7px;
    transform: none;
    top: 50px;
    width: auto;
    padding: 10px 12px;
    border-radius: 4px;
    box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.07),
      0px 12.963px 46.8519px rgba(0, 0, 0, 0.0531481),
      0px 7.7037px 25.4815px rgba(0, 0, 0, 0.0425185),
      0px 4px 13px rgba(0, 0, 0, 0.035),
      0px 1.62963px 6.51852px rgba(0, 0, 0, 0.0274815),
      0px 0.37037px 3.14815px rgba(0, 0, 0, 0.0168519);
    // media queries
    @include breakpoint($mobile-lg) {
      right: 5px;
    }

    &:before {
      display: none;
    }

    a {
      color: inherit;
    }
  }
}

.spr_sidenav--offcanvas,
.spr_sidenav--offcanvas.spr_mobilenav__drawer {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 9999;
  transition: transform 0.3s ease-in-out, max-width 0.3s ease-in-out;

  &.is-open {
    transform: none;
  }

  .spr_sidenav__inner {
    box-shadow: 0px -0.59851px 14.4307px rgba($black, 0.0969343),
      0px -2.01027px 48.4698px rgba($black, 0.143066),
      0px -9px 217px rgba($black, 0.24);
  }
}

.spr_sidenav__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($black, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 9998;

  &.is-open {
    pointer-events: auto;
    opacity: 1;
  }
}
