@import '../../../../styles/local-includes.scss';

.actionsmodal {
  button,
  .spr_iconbutton,
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: 500;
    height: 42px;
  }

  .adminbar__actions {
    flex-direction: column;

    .spr_iconbutton {
      color: $black;
      border-color: $lightgrey;
      margin: 0 0 15px;
    }
  }

  .create-a-product {
    order: 4;

    button {
      background-color: $black;
      color: $white;
      border-color: $black;
      margin: 0 0 15px;
    }
  }
}

.actionsmodal {
  .sharetooltip__content {
    display: grid;
    grid-template-areas:
      'sharetooltip-icons'
      'sharetooltip-title';

    .spr_copytoclipboard__message {
      // media queries
      @include breakpoint($tablet-md) {
        padding: 20px 24px;
        position: fixed;
        width: calc(100% - 40px);
        top: auto;
        left: 50%;
        right: auto;
        bottom: 220px;
        transform: translateX(-50%);
      }
    }
  }

  .spr_copytoclipboard {
    grid-area: sharetooltip-title;
  }

  .sharetooltip__title {
    @extend .spr_iconbutton;
    border: 1px solid $lightgrey;
    display: flex;
    align-items: Center;
    justify-content: center;
    padding: 0;

    &:after {
      display: none;
    }
  }

  .sharetooltip__shareicons {
    grid-area: sharetooltip-icons;
    margin-bottom: 15px;
    padding-top: 0;
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}
