@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.firstBanner {
  animation: fadeIn 1s;
  opacity: 1;
}

.secondBanner {
  animation: fadeIn 2s;
  opacity: 1;
}

.thirdBanner {
  animation: fadeIn 3s;
  opacity: 1;
}

.fourthBanner {
  animation: fadeIn 1s;
  opacity: 1;
}
