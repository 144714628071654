@import '../../../styles/local-includes.scss';

.collections__intro {
  h1 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
}

.collections__subtitle {
  font-size: 16px;
}

.collections__description {
  line-height: 172.3% !important;
}

.collectionsfeed {
  @include full-width($leftContentMargin);
  padding: 0 $leftContentMargin;
  background-color: #f6f6f9;
  border-top: 8px solid #edeff3;

  @include breakpoint($mobile-lg) {
    @include full-width($leftContentPaddingMobile);
    padding: 0;
  }
}

.collectionsfeed__item__name {
  h2 {
    margin-bottom: 5px;
    font-size: 14px !important;
  }

  p {
    line-height: 172.3% !important;
  }
}

.collectionsfeed__item__info,
.collectionsfeed__header {
  display: grid;
  grid-template-columns: 70% 100px;
  grid-template-rows: 1fr;
  justify-content: flex-start;
  align-items: center;
  width: 60%;

  @include breakpoint($mobile-lg) {
    grid-template-columns: 280px 88px;
  }
}

.collectionsfeed__header {
  font-weight: 500;
  color: $darkgrey;

  @include breakpoint($mobile-lg) {
    display: none;
  }
}

.collectionsfeed__item {
  @include full-width($leftContentMargin);
  display: flex;
  padding: 20px $leftContentMargin;
  align-items: center;
  border: solid 1px $lightergrey;

  @include breakpoint($mobile-lg) {
    @include full-width($leftContentPaddingMobile);
    padding-left: 46px;
    padding-right: 46px;
  }
}

.collectionsfeed__item__info {
  @include breakpoint($mobile-lg) {
    align-items: flex-end;
    padding: 0;
  }
}

.collectionsfeed__item__productcount {
  text-align: center;

  @include breakpoint($mobile-lg) {
    display: none;
  }
}

.collectionsfeed__item__edit {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @include breakpoint($mobile-lg) {
    align-items: center;
  }

  a {
    width: 40px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    border-radius: 2px;
    background: $white;

    @include breakpoint($mobile-lg) {
      border-radius: 200px;
      width: 42px;
      height: 42px;
    }

    &:hover {
      background-color: $darkgrey;
      border-color: $darkgrey;
      color: $white;
    }
  }
}

.hide {
  display: none;
}
