@import '../../styles/local-includes.scss';

.app-settings__tabs {
  padding-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mobile-lg) {
    margin-top: 1rem;
  }
  %tabs-menu {
    font-weight: 400;
    font-size: 14px;
    border-bottom: $lightgrey 1px solid;
    border-top: $lightgrey 1px solid;
  }
  .tabs-menu--horizontal-scrollable {
    @extend %tabs-menu;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    // Take up the full view width with padding for the border
    margin: 0px -50vw;
    padding: 0px 50vw;
  }
  .tabs-menu--fill-all-width {
    @extend %tabs-menu;
  }
  .tab-content-container {
    background-color: #f6f6f9;
    // Have the tab content max out the rest of the space on the page
    margin: 0px -50vw;
    padding: 0px 50vw;
    height: 100%;
  }
  %tab {
    padding: 0.75rem 1rem;
  }
  .tab--overflow-fashioned {
    @extend %tab;
    padding: 0.75rem 2rem;
  }
  .tab--50-percent-width {
    @extend %tab;
    width: 50%;
  }
  .tab--selected {
    @extend %tab;
    background-color: $lightgrey;
  }
  .tab--hovered {
    @extend %tab;
    // I don't know why but tabs are getting cut off. So, use box-shadow so we don't
    // interrupt the vertical flow and use a larger than necessary number to get
    // the border to show.
    box-shadow: inset 0px -5px 0px 0px $blue;
  }
  .tab-content--hidden {
    display: none;
  }
  .tab-content--shown {
    display: unset;
  }
  .collapsed-menu {
    float: right;
    margin-top: 0.75rem;
    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }
}
