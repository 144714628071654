@import '../../../styles/local-includes.scss';

.border-bottom--light {
  border-bottom: solid 1px $lightgrey;
}

.collection__header__wrapper {
  @include full-width($leftContentMargin);
  padding: 0 $leftContentMargin;
}

.layout--flex {
  display: flex;
  justify-content: space-between;

  &.baseline {
    align-items: baseline;
  }

  &.flex-start {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }
}

.collection__products__header {
  h1 {
    font-weight: 500 !important;
  }
}

.collection__products__header__add-product {
  @include breakpoint($mobile-lg) {
    height: 42px !important;
    min-width: 42px !important;
    border-radius: 25px;
    padding: 0 !important;
    width: unset !important;
  }
}

.collection__productfeed {
  flex: 1;
  background-color: #f6f6f9;
  border-top: 8px solid $lightergrey;
  padding: 14px $leftContentMargin;
  @include full-width($leftContentMargin);
  // media queries
  @include breakpoint($mobile-lg) {
    padding-left: 23px;
    padding-right: 23px;
  }
}

.collection__productfeed--padded-bottom,
.product_picker__feed.collection__productfeed--padded-bottom {
  padding-bottom: 82px;
}

.collection__header {
  margin-bottom: 16px;

  h1 {
    font-size: 32px;
    font-weight: 500;
  }

  @include breakpoint($mobile-lg) {
    padding-bottom: 0;
    margin-bottom: 10px;

    h1 {
      font-size: 26px;
    }
  }
}

.collection__productfeed__add {
  .icon {
    margin-right: 5px;
    height: 16px;
  }

  a {
    border: 1px dashed $mediumgrey;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkgrey;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    &:hover {
      border-color: $darkgrey;
    }
  }
}

.collection__productfeed__header__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  @include breakpoint($mobile-lg) {
    font-size: 12px;
    line-height: 18px;
    color: $darkgrey;
  }
}

.collection__productfeed__container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .input__checkbox.is-checked .input__mark {
    background-color: $blue;
    border-color: $blue;
  }

  .items__checked {
    background-color: $black;
    color: $white;
  }

  .collection__productfeed__header {
    .input__checkbox {
      margin: 0 2px 0 0;
    }

    .collection__title {
      @include breakpoint($mobile-lg) {
        font-size: 12px !important;
        color: $darkgrey !important;
      }
    }
  }
}

.spr_draggable__cell {
  border: none;
  border-radius: 2px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.07),
    0px 0px 4.59554px rgba(0, 0, 0, 0.0503198),
    0px 0px 2.45699px rgba(0, 0, 0, 0.0417275),
    0px 0px 1.37737px rgba(0, 0, 0, 0.035),
    0px 0px 0.731511px rgba(0, 0, 0, 0.0282725),
    0px 0px 0.304398px rgba(0, 0, 0, 0.0196802);
}

.spr_draggable__cellgroup {
  min-height: 55px !important;
}

.spr_draggable__cellcontent {
  padding: 8px !important;

  .input__checkbox {
    margin-bottom: 0 !important;
  }
}

.spr_draggable__column {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

@include breakpoint($mobile-lg) {
  .collection__products__header__description {
    max-width: 90%;
  }
}
