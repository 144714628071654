@import '../../../styles/local-includes.scss';

.overview__whatsnew {
  display: flex;
  width: 100%;
  @include breakpoint($mobile-lg) {
    @include mobile-scrolling;
    margin: 0;
    padding: 0;
    display: flex;
  }
}

.newImages {
  width: calc((100% - 84px) / 3);
  border-radius: 20px;
  cursor: pointer;
  margin-right: 12px;
  @include breakpoint($mobile-lg) {
    width: 300px;
  }
}

.newImages:first-of-type {
  margin-left: 30px;
}
.newImages:last-of-type {
  margin-right: 30px;
}
