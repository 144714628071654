@import '../../../styles/local-includes.scss';

.order__summary__cart__item__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.order__summary__cart {
  padding-top: 20px;
}

.order__summary__cart__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid $offgrey;
  max-width: 500px;
  width: auto;
}

.order__summary__cart__item__info {
  padding-left: 20px;
  font-size: 14px;
  width: 100%;
}

.order__summary__cart__item__order__details__qty {
  white-space: nowrap;
}

.order__summary__cart__item__order__details {
  span {
    color: $mediumgrey;
    padding-right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    &:last-of-type {
      color: $black;
    }
  }
}

.order__summary__action__container {
  display: flex;
  padding-top: 10px;
  padding-left: 28px;
}

.order__summary__action__item {
  background: $offwhite;
  height: 38px;
  width: 38px;
  border-radius: 228px;

  &:first-of-type {
    margin-right: 10px;
  }

  svg {
    padding-top: 3px;
    height: 18px;
  }
}
