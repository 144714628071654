@import '../../styles/local-includes.scss';

.app-list {
  background-color: $white;
  border: 1px solid #eaeaea;
  padding-top: 1.5rem;

  @media screen and (min-width: $mobile-lg) {
    border-radius: 10px;
  }
}

.app-list__title {
  padding: 0 2rem;
  margin-bottom: 1.5rem;

  h2 {
    font-size: 1.125rem;
  }
}

.app-list__item {
  font-size: 0.875rem;
  border-bottom: 1px solid #eaeaea;
  padding: 1.5rem 1.5rem;

  &:first-child {
    border-top: 1px solid #eaeaea;
  }

  h3 {
    font-size: 0.875rem;
  }

  @media screen and (min-width: $mobile-xsm) {
    display: flex;
    justify-content: space-between;

    .item__logo {
      margin-right: 1rem;
    }

    .item__button {
      display: inline-block;
      font-weight: 500;
      background-color: #1954eb;
      border-radius: 20px;
      color: $white;
      padding: 0.313rem 1.625rem;
    }
  }
}

.app-list__block {
  flex: 1 1 auto;

  &:first-child {
    display: flex;
  }

  &:last-child {
    flex: 0 0 auto;
    padding-left: 1rem;
  }

  h3 {
    margin-bottom: 0.25rem;
  }

  p {
    color: #6a6f72;
    font-weight: 400;
  }
}
