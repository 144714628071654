@import '../../styles/local-includes.scss';

.not_found {
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  left: 0;
  margin: auto;
  max-width: 1100px;
  padding: 30px 50px 30px 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;

  @include breakpoint($mobile-lg) {
    padding-top: 100px;
    position: relative;
  }
}

.not_found__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .not_found__header {
    color: $black;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    height: 47px;
    line-height: 130%;

    @include breakpoint($mobile-lg) {
      flex: 1;
      height: 0;
    }
  }

  .not_found__body {
    font-size: 18px;
    margin: 30px 0px;
  }
}

.not_found__dashboard_button {
  transition: ease-in-out 200ms;

  &:hover {
    background-color: $black;
    color: $white;
  }
}
