@import '../../../styles/local-includes.scss';

.scrollRight {
  cursor: pointer;
  margin-right: 30px;
}

.scrollLeft {
  cursor: pointer;
  position: relative;
  z-index: 50000;
  margin-right: 12px;
}
.spacer {
  width: 100%;
}
.integration-shelf {
  scroll-behavior: smooth;
  display: flex;
  overflow-x: scroll;
  transition: 2s;
  padding-bottom: 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.integration-shelf__title {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}
