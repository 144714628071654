@import '../../styles/local-includes.scss';

.dashboard__notifications {
  .spr_toast-container {
    min-width: 300px;

    @include breakpoint($mobile-lg) {
      z-index: 100000000 !important;
    }
  }
}
