@import '../../../../styles/local-includes.scss';

.item_selection_header {
  @include full-width($leftContentMargin);
  padding: 11px 30px;
  height: 41px;

  .input__checkbox input {
    margin-right: 0;
  }

  &.items-checked {
    background-color: $darkergrey;
    color: $white;
    font-weight: 500;
  }

  .icon {
    color: $white;
  }

  .collection__selected__products {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    height: 20px;
  }
}
