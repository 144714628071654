@import '../../../styles/local-includes.scss';

.samples-cart-is-open {
  // targeting the iframe here to increase specificity and avoid using important
  #intercom-container iframe.intercom-launcher-frame,
  .intercom-lightweight-app-launcher {
    bottom: 90px;
    transition: bottom 500ms;
  }

  #intercom-container .intercom-messenger-frame {
    bottom: 170px;
    transition: bottom 500ms;
  }
}

.spring-mark-is-open {
  #intercom-container iframe.intercom-launcher-frame,
  .intercom-lightweight-app-launcher {
    right: 400px;
    transition: right 1s;
  }

  #intercom-container .intercom-messenger-frame {
    right: 400px;
    transition: right 1s;
  }
}

.intercom-lightweight-app-launcher {
  background-color: $black;
}

iframe.intercom-launcher-frame,
.intercom-lightweight-app-launcher,
#intercom-container .intercom-messenger-frame {
  transition: bottom 500ms, right 1s;
}
