@import '../../../styles/local-includes.scss';

.email-verify-bar__content {
  background: rgba(255, 247, 234, 1);
  border: 1px solid rgba(255, 172, 50, 1);
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  .email-verify-bar__body {
    flex-shrink: 1;
    flex-grow: 1;
    h1 {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      padding-bottom: 0 !important;
      margin-bottom: 0;
      a {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .break-box {
    width: 32px;
  }
  .email-verify-bar__button {
    flex-shrink: 0;
    flex-grow: 0;
    button {
      border-radius: 8px;
      background: rgba(255, 172, 50, 1);
      border: 0 solid rgba(255, 172, 50, 1);
      color: rgba(0, 0, 0, 1);
    }
  }
  @include breakpoint($mobile-lg) {
    flex-direction: column;
    .break-box {
      width: 0;
    }
  }
}
