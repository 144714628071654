@import '../../../styles/local-includes.scss';

$black: #6a6f72;

.purchase_checkout_modal__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.purchase_checkout_modal__card_data {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  @include breakpoint($mobile-lg) {
    width: 100%;
  }
}

.purchase_checkout_modal__card_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 115px;
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid $lightgrey;
  box-sizing: border-box;
  padding: 24px;
  .purchase_checkout_modal__card_logo_container {
    width: 120px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 17.947px rgba(0, 0, 0, 0.1);
    max-height: 68px;
    display: flex;
    word-wrap: none;
    flex-direction: row;
    justify-content: center;
  }
  .purchase_checkout_modal__card_logo {
    height: 68px;
    margin: 0 auto;
    object-fit: cover;
  }
  p {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #08080d;
  }

  button {
    width: 104px;
    height: 42px;
    align-items: center;
    background: $white;
    border: 1px solid $lightgrey;
    border-radius: 200px;
    font-size: 15px;
    font-weight: 500;
    @include breakpoint($mobile-lg) {
      width: 100%;
      margin-top: 16px;
    }
  }
  @include breakpoint($mobile-lg) {
    justify-content: center;
    height: auto;
  }
}
