@import '../../../styles/local-includes.scss';
.spring-mark-settings__container {
  padding-top: 30px;

  p {
    padding-top: 8px;
  }
}

.spring-mark-settings__listing-link {
  color: #222;
  font-weight: 500;
  text-decoration: underline;
}
