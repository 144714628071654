@import '../../styles/local-includes.scss';

.analytics {
  font-family: 'DM Sans', sans-serif;
  position: relative;
  margin: 40px auto;
  max-width: 920px;
  min-height: 100vh;

  .title {
    display: flex;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    .custom-toggle {
      margin: 0;
      align-self: flex-end;
      z-index: 1;
    }
  }

  @include breakpoint($desktop-sm) {
    .overview__content > .title {
      margin-left: 20px;
    }
  }

  .summary {
    display: flex;
    margin-top: 28px;
    gap: 20px;

    .item {
      background-color: #ffffff;
      flex: 1 0 21%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      margin: 0;
      border-radius: 8px;
      text-align: center;

      .icon {
        height: 24px;
        margin-bottom: 16px;
      }

      .number {
        font-family: 'DM Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: -0.24px;
        margin-bottom: 10px;
      }

      .label {
        font-family: Inter;
        color: #595959;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .item:first-child {
      margin-left: 0;
    }

    .item:last-child {
      margin-right: 0;
    }
  }

  @include breakpoint($desktop-sm) {
    .summary {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @include breakpoint($tablet-lg) {
    .summary {
      flex-wrap: wrap;

      .item {
        flex: 1 0 30%;
      }
    }
  }

  @include breakpoint($tablet-sm) {
    .summary {
      .item {
        flex: 0 0 47%;
      }
    }
  }

  @include breakpoint($mobile-sm) {
    .summary {
      .item {
        gap: 10px;
        flex: 0 0 45%;
      }
    }
  }

  .content {
    border-radius: 8px;
    margin: 28px 0 0;
    padding: 24px 24px 28px;

    .title {
      h3 {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .chart {
      height: 300px;
      margin-bottom: 10px;
    }

    .chart-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .chart-container {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: space-between;

      .small {
        flex: 1 1 40%;
        min-width: 240px;
      }
    }

    #profitOverTime .recharts-legend-wrapper {
      top: -20px !important;
    }

    @include breakpoint($tablet-lg) {
      .chart-container {
        flex-wrap: wrap;
      }

      #profitOverTime .recharts-legend-wrapper {
        top: 10px !important;
      }
    }

    .one-of-two {
      width: 49%;
      background-color: #ffffff;
      padding: 24px;
      border-radius: 8px;
    }

    @include breakpoint($tablet-lg) {
      .one-of-two {
        width: 100%;
      }
    }

    .actions {
      display: flex;
      gap: 30px;
      justify-content: center;

      button {
        border: 1px solid #787878;
        padding: 5px 10px;
        border-radius: 5px;

        &.selected {
          border: 1px solid #1954eb;
          background: #1954eb;
          color: #ffffff;
        }
      }
    }

    .rdt_TableRow {
      background-color: #f6f6f9;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
    }
  }

  .content.no-padding {
    padding: 0;

    @include breakpoint($tablet-lg) {
      .chart-wrapper {
        flex-wrap: wrap;
        row-gap: 28px;
      }
    }
  }

  .content:not(:has(.one-of-two)) {
    background-color: #ffffff;
    /* Apply white background if there are no .one-of-two children */
  }

  .customPieLegend {
    display: flex;
    justify-content: center;
    width: 330px;

    .truncateName {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      /** display max 2 lines of category name: **/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.4em;
      max-height: calc(1.4em * 2);
    }

    .legendItem {
      flex: 1;
      display: flex;
      align-items: center;
      color: #080808;
      padding-right: 9px;
      font-weight: 500;
    }

    .legendItem:last-of-type {
      padding-right: 0px;
    }

    .legendColor {
      opacity: 1;
      height: 8px;
      border-radius: 2px;
      margin-right: 6px;
      flex: 0 0 18%;
    }
  }

  button.calendar {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
  }

  .custom-tooltip {
    background-color: #000000;
    color: #ffffff;
    padding: 6px 8px;

    .label {
      display: block;
    }
  }

  .custom-select {
    .MuiSelect-root {
      background-color: white;
      min-width: 200px;
      font-size: 14px;
      border-radius: 8px; // Default border radius
      box-shadow: none; // No shadow initially
      transition: border-radius 0.2s ease;

      @include breakpoint($mobile-lg) {
        min-width: 75px;
        width: 75px;

        .range-label {
          display: none;
        }
      }

      .MuiSelect-select {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-style: none; // No border outline
      }

      // When dropdown is expanded, remove bottom border radius
      &.Mui-expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
      }
    }

    // Restore full border radius when dropdown is closed
    .MuiSelect-root:not(.Mui-expanded) {
      border-radius: 8px;
    }

    // Ensure dropdown items use the desired font size
    .MuiMenuItem-root {
      font-size: 14px !important;
    }
  }

  .actionableInsight {
    .spr_tooltip__content.tooltip__content {
      min-height: 300px;
      background-color: #000000;
      border-radius: 6px;
      border: 1px solid #fd950d;
      color: #ffffff;
      font-size: 16px;

      h2 {
        font-size: 18px;
        padding: 10px 0 7px;
      }

      .header {
        color: #fca80c;
        height: 30px;
        font-size: 14px;

        .img {
          margin-right: 5px;
        }
      }
    }

    .spr_tooltip__content:before {
      width: 0;
      height: 0;
    }
  }

  @media screen and (min-width: $tablet-sm) {
    .actionableInsight.align-left .spr_tooltip__content {
      width: 400px;
      right: calc(100% - 17px);
      top: -10px;
    }
  }
}
