@import '../../../styles/local-includes.scss';
@import '../../../styles/includes/variables/color';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.integrations_page_container {
  max-width: 920px;
  margin: 0 auto;
  padding: 40px 0;
  animation: fadeIn 2s;
}

.integrations_read_more_btn {
  text-transform: none !important;
  padding: 12px 0px !important;
}

.integrations_page_setup_btn {
  /* Primary / Blue */
  margin: 15px !important;
  background: #1954eb !important;
  color: #ffffff !important;
  /* Primary / Blue */
  border: 1px solid #1954eb !important;
  border-radius: 12px !important;
  padding: 10px 24px;
  gap: 8px;
  width: calc(100% - 30px) !important;
  height: 50px !important;
  text-transform: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: -0.01em !important;
}

.integrations_page_sticky {
  display: none;
}

.integrations_page_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
}

.integrations_banner {
  display: flex;
  flex-wrap: nowrap;
  max-width: 920px;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 324px;
  margin: 0 auto;
  margin-top: 26px;
}

.integrations_banner::-webkit-scrollbar {
  display: none;
}

.integrations_content {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  max-width: 920px;
}

.integrations_page_instructions {
  width: calc((100% / 3) * 2);
  /* Neutral/05 */
  background: #f8f8f8;
  /* Neutral / 20 */
  height: fit-content;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  overflow: hidden;
}

.integrations_page_instructions_header {
  background-color: #ffffff;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.integrations_page_overview {
  width: calc(100% / 3);
  padding: 0 0 0 22px;
  order: 2;
}

.integration_page_tabs {
  width: 100%;
  padding: 12px;
  border-radius: 2000px;
  background-color: #f2f2f2;
}

.integration_page_tab_btn {
  font-family: 'Inter' !important;
  font-style: normal;
  border-radius: 32px !important;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.01em;
  width: 50%;
  height: 50px;
  color: #08080d !important;
  text-transform: none !important;
  transition: 3s;
}

.integration_page_tab_btn_active {
  width: 50%;
  height: 50px;
  background-color: #1954eb !important;
  color: #ffffff !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.01em;
  transition: 3s;
}

.integrations_page_content_container {
  padding: 24px;
  ol li {
    list-style: decimal;
    list-style-position: outside;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

.integrations_page_howto_prelude {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 7px;
  width: 100%;
  background: #e8eefd;
  border: 1px solid #1954eb;
  border-radius: 8px;
}

.integrations_page_accordion {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: -0.01em;
}

.integrations_page_eligibility {
  width: 100%;
  height: auto;
  /* Semantic/Warning light */
  background: #fff7ea;
  /* Semantic / Warning */
  border: 1px solid #ffac32;
  border-radius: 8px;
  margin: 0 auto;
  padding: 16px;
}
.integrations_page_eligiblilty_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  /* Primary / Midnight */
  color: #08080d;
  margin-bottom: 7px;
}
.integrations_page_eligibility_content {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  /* Neutral / 80 */
  color: #404040;
  max-width: 520px;
}

.integrations_page_advice {
  width: 100%;
  height: auto;
  background: #fff7ea;
  border: 1px solid #ffac32;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 16px;

  .advice_bold {
    font-weight: 600;
  }
}

.integrations_page_setup_time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.01em;
  /* Neutral / 30 */
  color: #bfbfbf;
}

.integrations_page_setup_prelude {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148%;
  /* or 24px */
  text-align: center;
  letter-spacing: -0.01em;
  /* Neutral / 80 */
  color: #404040;
}

.integrations_page_step_card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  /* Neutral/00 */
  background: #ffffff;
  /* Neutral / 20 */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 16px;
}

.integrations_page_step_info {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin-top: 2rem;
  margin-left: 6px;
  /* or 24px */

  letter-spacing: -0.01em;
  color: #2e2e2e;
  .integrations_steps_bold {
    font-weight: 600;
  }
  .integrations_page_column {
    flex-direction: row;
  }
}

.integrations_page_step_text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148%;
  /* or 24px */

  letter-spacing: -0.01em;
  color: #2e2e2e;
  .integrations_steps_bold {
    font-weight: 600;
  }
  .integrations_page_column {
    flex-direction: row;
  }
}

.integrations_step_link {
  text-decoration: underline;
  color: #404040;
}
.integrations_prelude_link {
  color: #1954eb;
}
.integrations_page_step_note {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 7px;
  /* Semantic/Warning light */
  background: #fff7ea;
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: start;
  letter-spacing: -0.01em;
  /* Neutral/80 */
  color: #404040;
}
.integrations_page_step_note_title {
  color: #08080d;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.integrations_step_circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 32px;
  height: 32px;
  /* Neutral/05 */
  background: #f8f8f8;
  /* Neutral / 20 */
  border: 1px solid #d9d9d9;
  border-radius: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.01em;

  /* Neutral / 70 */

  color: #595959;
  margin-right: 12px;
}

.integrations_page_btn_complete {
  /* Primary / Blue */
  margin-top: 16px !important;
  background: #1954eb !important;
  color: #ffffff !important;
  /* Primary / Blue */
  border: 1px solid #1954eb !important;
  border-radius: 12px !important;
  padding: 10px 24px;
  gap: 8px;
  width: 241px !important;
  height: 50px !important;
  text-transform: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: -0.01em !important;
}

.integrations_page_overview_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin-bottom: 16px;

  /* Primary / Midnight */

  color: #08080d;
}
.integrations_page_feature_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;

  /* Primary / Midnight */

  color: #08080d;
}
.integrations_page_overview_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 148%;
  /* or 21px */

  letter-spacing: -0.01em;

  /* Neutral / 80 */

  color: #404040;
}

.integrations_page_feature {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 100%;
  // height: 87px;

  /* Primary / white */

  background: #ffffff;
  /* Neutral / 20 */

  border: 1px solid #d9d9d9;
  border-radius: 8px;
  .integrations_feature_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #08080d;
  }
  .integrations_feature_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #404040;
  }
}

.integrations_page_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 228px;
  gap: 20px;
  margin-top: 10px;
  padding: 16px;

  /* Primary / white */

  background: #ffffff;
  /* Neutral / 20 */

  border: 1px solid #d9d9d9;
  border-radius: 8px;
  .integrations_page_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #08080d;
  }
  .integrations_page_card_icon {
    width: 30px;
    height: 30px;
  }
}

.integrations_page_resource_link {
  margin-top: 15px;
  background-color: #ffffff;
  a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    /* Primary / Blue */
    color: #1954eb;
  }
}
.integrations_page_help_2 {
  display: none;
  margin: 0 24px;
}

.youtube_studio_button {
  width: auto;
  height: 50px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #08080d;
  white-space: nowrap;
  img {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
}

.info_button {
  width: auto;
  height: 50px;
  display: flex;
  padding: 10px 21px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  margin: 18px 0 0 6px;
  border-radius: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #08080d;
  white-space: nowrap;
  img {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
}

.steps_button {
  width: auto;
  height: 50px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  margin: 0 12px;
  border-radius: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #08080d;
  white-space: nowrap;
  img {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
}

.copy_code_button {
  width: auto;
  height: 50px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: $link-blue;
  border: 1px solid #d9d9d9;
  margin: 0 12px;
  border-radius: 16px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #ffffff;
  white-space: nowrap;
  img {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .integrations_page_container {
    margin: 0 24px;
  }
}

@media only screen and (max-width: 950px) {
  .integrations_page_sticky {
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 82px;
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  }

  .integrations_page_feature {
    min-width: 298px;
    margin-right: 10px;
  }

  .integrations_page_feature:last-child {
    margin-right: 0px;
  }

  .integrations_page_features::-webkit-scrollbar {
    display: none;
  }

  .integrations_page_features {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 24px;
  }

  .integrations_page_container {
    margin: 0;
    padding: 24px 0;
  }

  .integrations_content {
    flex-direction: column;
  }

  .integrations_page_instructions {
    width: 100%;
    order: 2;
  }

  .integrations_page_overview {
    width: 100%;
    padding: 0;
    order: 1;
  }
  .integrations_page_overview_content {
    margin: 0 24px;
  }
  .integrations_page_feature_content {
    padding: 0 24px;
  }
  .integrations_page_feature_title {
    margin: 0 24px;
  }

  .integrations_page_instructions {
    border: none;
    border-radius: 0;
  }

  .integrations_page_title {
    background: #f8f8f8;
    border-radius: 12px;
    margin: 0 24px;
    height: 56px;
    padding: 16px;
  }
  .integrations_banner {
    padding: 0 24px;
  }
  .integrations_step_circle {
    min-width: 32px;
  }
  .integrations_page_subtitle {
    display: none;
  }
  .integrations_page_help_1 {
    display: none;
  }
  .integrations_page_help_2 {
    display: block;
  }
}
.integrations_page_step_button {
  background: #1954eb;
  color: #ffffff;
  border: 1px solid #1954eb;
  border-radius: 12px;
  padding: 6px;
  width: 10rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.01em;
}
.integrations_page_hubspot_modal_container {
  padding: 10px 20px;
  border-radius: 12px;
  max-width: 24rem;
}

.deprecation-message-container {
  height: 140px;
  background-color: hsla(0, 79%, 95%, 0.2);
  margin: 2rem auto;
  border: 1px solid hsla(0, 97%, 44%, 1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $desktop-sm) {
    width: 100%;
  }
  @media only screen and (max-width: $tablet-md) {
    height: 180px;
    width: 90%;
  }
  @media only screen and (max-width: $mobile-lg) {
    height: 190px;
    width: 90%;
  }
  .warning-toast {
    background-color: hsla(0, 97%, 44%, 1);
    width: 44px;
    border-radius: 10px 0 0 10px;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .deprecation-text-container {
    background-color: hsla(0, 79%, 95%, 0.2);
    height: 100%;
    border-radius: 0 12px 12px 0;
    padding: 0.75rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    .deprecation-text {
      display: flex;
      flex-direction: column;
      font-family: Inter, sans-serif;
      p {
        font-size: 1rem;
        line-height: 1rem;
      }
      span {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 500;
      }
      a {
        background-color: white;
        display: flex;
        border: 1px solid hsla(0, 0%, 85%, 1);
        border-radius: 12px;
        color: hsla(240, 24%, 4%, 1);
        font-weight: 500;
        font-size: 0.875rem;
        max-width: 100px;
        height: 42px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media only screen and (max-width: $tablet-md) {
          margin-top: 1rem;
        }
        @media only screen and (max-width: $mobile-lg) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
.integrations_page_hidden_form {
  display: none;
}
.integrations_youtube_disconnect_setup {
  font-family: 'Inter';
  display: grid;
  p:first-child {
    font-size: 1rem;
    font-weight: 400;
    max-width: 33.25rem;
    padding: 0 10px;
    margin-bottom: 0;
    text-align: center;
  }
  button {
    margin-top: 1.5rem;
    justify-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 280px;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    padding: 16px 24px;
    span {
      margin-left: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      width: 197px;
    }
  }
}

.integrations_page_howto_disconnect_button {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    padding: 16px 24px;
    background-color: white;
    width: 284px;
    height: 50px;
    span {
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 500;
      margin-left: 0.5rem;
      width: 197px;
    }
  }
}

.youtube_disconnect_modal {
  position: relative;
  font-family: Inter;
  padding: 0;
  border: 1px solid white;
  justify-items: center;
  border-radius: 16px;
  width: 525px;
  height: 364px;
  box-shadow: 0px 0.91px 4.15px 0px hsla(0, 0%, 0%, 0.03),
    0px 2.19px 9.98px 0px hsla(0, 0%, 0%, 0.04),
    0px 4.13px 18.78px 0px hsla(0, 0%, 0%, 0.05),
    0px 7.37px 33.5px 0px hsla(0, 0%, 0%, 0.07),
    0px 13.79px 62.67px 0px hsla(0, 0%, 0%, 0.08),
    0px 33px 150px 0px hsla(0, 0%, 0%, 0.11);
  hgroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 24px 0px;
    margin-bottom: 1rem;
  }
  article {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    font-size: 0.875rem;
    line-height: 1.1rem;
    ul {
      padding: 0 24px;
      list-style: initial;
    }
    ul li::marker {
      color: black;
      font-size: 1rem;
    }
  }
  hr {
    color: #eaeaea;
    margin: 1.5rem 0 0;
  }
  footer {
    height: 84px;
    display: flex;
    width: 100%;
    padding: 0 24px;
    align-items: center;
    font-size: 1rem;
    justify-content: space-around;
    button {
      border-radius: 16px;
      padding: 10px 24px;
      font-weight: 500;
      height: 50px;
      font-size: 0.875rem;
    }
    button:first-of-type {
      border: 1px solid #d9d9d9;
      color: #262626;
      height: 50px;
    }
    button:last-of-type {
      background: hsla(223, 84%, 51%, 1);
      border: 1px solid hsla(223, 84%, 51%, 1);
      color: hsla(0, 0%, 100%, 1);
    }
  }
  @media only screen and (max-width: $mobile-lg) {
    width: 375px;
    height: 525px;
    hgroup {
      svg {
        display: none;
      }
    }
    footer {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      button {
        width: 100%;
      }
      button:first-of-type {
        margin: 1rem 0 0.5rem;
      }
    }
  }
}
.youtube_disconnect_modal_close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.youtube_disconnect_modal-after_disconnect {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 66px 66px 32px;
  text-align: center;
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2.125rem;
  }
  button {
    background: hsla(223, 84%, 51%, 1);
    border: 1px solid hsla(223, 84%, 51%, 1);
    color: hsla(0, 0%, 100%, 1);
    border-radius: 16px;
    padding: 10px 24px;
    font-weight: 500;
    height: 50px;
    font-size: 0.875rem;
  }
  @media only screen and (max-width: $mobile-lg) {
    padding: 20px;
  }
}
