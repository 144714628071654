@import '../../styles/local-includes.scss';

.accordion-title {
  background-color: $lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  .accordion-title__text {
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-size: 1rem;
  }
}
.accordion-items {
  border: 1px solid $lightgrey;
  padding: 1rem 1rem 0;
}
