@import '../../styles/local-includes.scss';

.samples__wrapper {
  z-index: -1;

  @media screen and (max-height: 890px) {
    padding-bottom: 70px;
  }
}

.samples__wrapper__breadcrumbs {
  padding-bottom: 10px;
}
