.slider-and-input {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: #f8f8f8;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
}

.slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.min-products {
  opacity: 0.6;
  font-weight: 500;
}

.max-products {
  width: 24px;
  opacity: 0.6;
  font-weight: 500;
}
