@import '../../styles/local-includes.scss';

.apps__cta-banner {
  vertical-align: center;
  width: 100%;
  padding: 1.5rem;

  @media screen and (min-width: $mobile-lg) {
    padding: 1.5rem 0;
  }

  .cta-banner-content--left-aligned-content-on-shrinking {
    align-items: center;
    background-color: $black;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    border-radius: 20px;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    padding: 0.75rem 0;
    width: 100%;
    max-width: 50rem;

    @media screen and (max-width: $mobile-lg) {
      align-items: baseline;
      background-position: center;
      flex-direction: column;
      justify-content: unset;
      padding: 1rem 0;
    }

    .text {
      margin: 1rem;
      max-width: 65%;
      @media screen and (max-width: $mobile-lg) {
        max-width: 100%;
      }
    }

    h1 {
      font-family: DM Sans, sans-serif;
      font-size: 1.5rem;
      color: $white;
      @media screen and (max-width: $mobile-lg) {
        font-size: 1rem;
      }
    }

    p {
      font-family: Inter, sans-serif;
      font-size: 0.9rem;
      color: $white;
      margin: 0;
    }

    a {
      border-radius: 20px;
      background-color: #1954eb;
      color: $white;
      font-family: Inter, sans-serif;
      font-size: 0.8rem;
      font-weight: 500;
      margin-left: auto;
      margin-right: 1.5rem;
      order: 2;
      padding: 0.5rem 0.75rem;
      @media screen and (max-width: $mobile-lg) {
        margin-left: 1rem;
      }
    }
  }

  .cta-banner-content--left-aligned-content {
    padding: 1.5rem 1.5rem 9rem;

    @media screen and (min-width: $mobile-xsm) {
      align-items: baseline;
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: $mobile-lg) {
        padding: 1.5rem;
        background-position: 30% 50%;
      }

      .text {
        margin-bottom: 1rem;
        max-width: 100%;

        @media screen and (min-width: $mobile-lg) and (max-width: $tablet-md) {
          max-width: 50%;
        }

        @media screen and (min-width: $tablet-md) {
          max-width: 40%;
        }
      }

      h1 {
        font-family: DM Sans, sans-serif;
        font-size: 1.7rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
      }

      p {
        color: #6a6f72;
        font-family: Inter, sans-serif;
        font-size: 0.9rem;
        margin: unset;
      }

      a {
        display: none; // remove this when marketing team has a blog post to redirect
        border-radius: 20px;
        border: solid 1px #1954eb;
        color: #1954eb;
        font-family: Inter, sans-serif;
        font-size: 0.8rem;
        font-weight: 500;
        margin-left: 1rem;
        padding: 0.5rem 0.75rem;

        @media screen and (min-width: $mobile-lg) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
