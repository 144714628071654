@import '../../../styles/local-includes.scss';

.spr_toaster-container {
  @include breakpoint($mobile-lg) {
    width: 100%;
    left: 0;
    top: 16px !important;
    z-index: 100000000 !important;
  }

  .spr_toast-container {
    width: auto !important;

    @include breakpoint($tablet-sm) {
      margin: auto 60px;
    }

    @include breakpoint($mobile-lg) {
      margin: auto;
    }

    @include breakpoint($mobile-md) {
      max-width: 100% !important;
      margin: auto 16px;
    }

    .spr_toast-content {
      h1 {
        font-size: 14px !important;
      }
      div {
        font-weight: 400;
        color: $darkgrey;
      }
    }

    .success ~ .spr_toast-content {
      padding-right: 50px !important;

      @include breakpoint($mobile-lg) {
        padding-right: 16px !important;
      }
    }

    .danger ~ .spr_toast-content {
      .spr_toast-action {
        margin-top: 15px !important;

        button {
          border: 1px solid #b7b7b7;
          padding: 6px 12px;
          border-radius: 2px;
        }
      }

      @include breakpoint($mobile-lg) {
        padding-right: 0 !important;

        h1 {
          letter-spacing: 0.02em;
        }

        div > div > div:last-child {
          letter-spacing: -0.02em;
        }

        .spr_toast-action {
          button {
            width: auto;
          }
        }
      }
    }
  }
}
