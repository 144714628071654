@import '../../../../styles/local-includes.scss';

.sharemodal {
  .sharetooltip__content {
    display: grid;
    grid-template-areas:
      'sharetooltip-icons'
      'sharetooltip-title';

    .spr_copytoclipboard__message {
      // media queries
      @include breakpoint($tablet-md) {
        padding: 20px 24px;
        position: fixed;
        width: calc(100% - 40px);
        top: auto;
        left: 50%;
        right: auto;
        bottom: 220px;
        transform: translateX(-50%);
      }
    }
  }

  .spr_copytoclipboard {
    grid-area: sharetooltip-title;
  }

  .sharetooltip__title {
    height: 42px;
    width: 100%;
    background-color: transparent;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid $lightgrey;
    margin-bottom: 15px;

    &:after {
      display: none;
    }
  }

  .sharetooltip__shareicons {
    grid-area: sharetooltip-icons;
    margin-bottom: 15px;
    padding-top: 0;
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}

.sharemodal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-weight: 500;
  height: 42px;
}
