@import '../../styles/local-includes.scss';

.collections__controls button {
  background-color: $offwhite;
  border-color: $offwhite;
  color: $darkgrey;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 0;
  margin-bottom: 10px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &.is-active {
    background-color: $black;
    color: $white;
  }
}
