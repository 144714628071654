.listing__frame {
  z-index: 0;
  width: 100%;
  min-height: calc(100vh - 56px);
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 56px);
  }

  .spr_loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
