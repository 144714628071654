@import '../../styles/local-includes.scss';

.purchases {
  display: flex;

  @include breakpoint($mobile-lg) {
    display: block;
  }
}
.sub_text {
  font-size: 14px;
}
.purchases__content {
  flex-basis: 640px;
  flex-grow: 0;
  flex: 1;
  min-height: calc(100vh - 105px);

  // media queries
  @include breakpoint($desktop-lg) {
    flex-basis: 580px;
  }
  @include breakpoint($desktop-sm) {
    flex-basis: 914px;
  }
  @include breakpoint($mobile-lg) {
    padding: 0;
    min-height: 0;
  }
}

.purchases__header,
.purchases__body {
  padding: 0 195px;

  @include breakpoint($mobile-lg) {
    padding: $contentPaddingMobile;
  }
}

.purchases__header {
  background-color: $white;

  @include breakpoint($mobile-lg) {
    padding: $contentPaddingMobile;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
  }

  .sub_text {
    color: $darkgrey;
    font-weight: 400;
    max-width: 600px;
    margin-bottom: 0;

    @include breakpoint($mobile-lg) {
      font-size: 16px;
    }
  }
}

.purchases__back_nav {
  font-size: 16px;
  position: relative;
  width: 100%;

  &.btn,
  &.btn--transparent {
    background-color: $white;
    border-bottom-width: 1px;
    border-color: $lightgrey;
    border-width: 0;
  }

  .icon,
  .spr_icon {
    left: 0;
    padding-left: 20px;
    position: absolute;
  }
}

.purchases__tabs {
  background-color: $white;
  border: solid 1px $lightgrey;
  border-left: 0;
  border-right: 0;

  @include breakpoint($mobile-lg) {
    background-color: transparent;
    border-bottom: 0;
  }
}

.tabs_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 195px;
  height: 42px;

  @include breakpoint($mobile-lg) {
    padding: $contentPaddingMobile;
  }

  .purchases_tabs {
    font-size: 14px;
    background: none;
    border-radius: 0;
    border: none;
    padding: 0;
    margin-top: auto;
    height: 42px;

    @include breakpoint($mobile-lg) {
      display: grid;
      font-size: 14px;
      font-weight: 400;
      grid-auto-rows: 48px;
      grid-template-columns: 1fr;
      row-gap: 8px;
    }

    .spr_tabs__item {
      border-radius: 0;
      flex-grow: 0;
      height: 42px;
      @include breakpoint($mobile-lg) {
        background-color: $white;
        border: 1px solid $lightgrey;
        border-radius: 8px;
        margin: 0;
        padding: 0;
      }

      &.is-active {
        background-color: transparent;
        border-bottom: solid 1px $blue;
      }
    }

    button {
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }
  }
}

.listing__none,
.subscriptions__none {
  align-content: center;
  border-radius: 10px;
  border: dashed 2px $mediumgrey;
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: $contentMargin;

  & > * {
    color: $darkgrey;
    font-weight: 400;
    margin: 0;
  }
}
