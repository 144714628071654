@import '../../styles/local-includes.scss';
.app-settings__general__service-management {
  margin-top: 2.5rem;

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    @media screen and (max-width: $tablet-md) {
      flex-direction: column;
      width: 100%;
    }
    %content-container {
      background-color: $lightgrey;
      border-radius: 20px;
      margin-right: 1.5rem;
      width: 72rem;
      padding: 6%;
    }
    .content-container--img {
      @extend %content-container;
      padding: 2rem 0 0;
      width: 72rem;
      @media screen and (max-width: $tablet-md) {
        width: 100%;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    .media-item--img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 50%;
      @media screen and (max-width: $tablet-md) {
        width: 30%;
      }
    }
    .content-container--video {
      @extend %content-container;
      height: 100%;
      max-width: 440px;
      @media screen and (max-width: $tablet-md) {
        width: 100%;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 6%;
      }

      .video-content {
        position: relative;
        padding-top: 56.25%;
        height: 0;

        .media-item--video {
          border-radius: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .item {
      color: $darkgrey;
      font-weight: 500;
      margin: 0.25rem 0 1rem 0.2rem;
      .bullet {
        border: 1px solid $mediumgrey;
        border-radius: 50%;
        line-height: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        min-width: 1.5rem;
        text-align: center;
      }
    }
  }
}
