@import '../../styles/local-includes.scss';

.main {
  display: flex;
  background: $black;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  // IOS browsers do not handle scrolling on nested elements very well. In this
  // case, the scroll kept miscalculating and got stuck at the bottom and top.
  //
  // To fix this, we scroll on this element instead it's child element (see
  // comment in `.main__content`).
  @include breakpoint($mobile-lg) {
    max-height: unset;
    overflow-y: auto;
  }
}

.main__sidebar {
  flex-grow: 0;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
}

.main__content {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  flex: 1;
  min-height: 100vh;
  width: 100%;

  @include breakpoint($mobile-lg) {
    // Spacing to make it look nice
    padding-bottom: 65px;
    padding-left: 0;
    padding-top: 60px;

    // Disable scrolling on this element for mobile screens and move that
    // responsibility to the parent element (see comment in `.main`).
    overflow-y: unset;
  }
}

.spr_actionbar__inner {
  background: $white;
}

.spr_mobilenav__container {
  pointer-events: none;
}

.main__content__default__bg {
  background-color: $offwhite;
}

.main__content__bg__white {
  background-color: $white;
}

.spr_create_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  width: auto;
  height: 40px;
  background: #1954eb;
  border: 1px solid #1954eb;
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.hide_create_button .spr_create_button {
  display: none;
}

.hide_create_button h2 {
  width: 100%;
  padding-left: 10px;
}

.spr_pen_style {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
