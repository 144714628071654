@import '../../styles/local-includes.scss';

.app-settings__header {
  margin-top: 2rem;
  width: 100%;
  .logo {
    height: 3rem;
    margin-right: 1rem;
    margin-top: 0.75rem;
    width: 3rem;
    @media screen and (max-width: $mobile-lg) {
      margin-right: 0.5rem;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  .subtitle {
    color: $darkgrey;
    font-weight: 400;
    margin-top: 0.25rem;
  }
  .title {
    font-family: DM Sans, sans-serif;
    font-size: 1.5rem;
    min-width: 10rem;
    @media screen and (max-width: $mobile-lg) {
      font-size: 1.1rem;
      min-width: 8.5rem;
    }
  }
  .status {
    align-items: center;
    border: 0.1rem solid $mediumgrey;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-top: 0.75rem;
    order: 2;
    height: 100%;
    padding: 0.4rem;
    .indicator {
      background: $green;
      border-radius: 50%;
      height: 0.65rem;
      width: 0.65rem;
    }
    .text {
      align-self: center;
      color: $darkgrey;
      font-size: 0.6rem;
      margin: 0 0.25rem;
    }
  }
}
