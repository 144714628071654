@import '../../styles/local-includes.scss';

.apps-category-page {
  @media screen and (min-width: $mobile-xsm) {
    max-width: 65rem;
    margin: auto;
  }

  @media screen and (min-width: $mobile-lg) {
    padding: 0 3rem 3rem;
  }
}

.apps-category-page__cta-banner {
  @media screen and (min-width: $mobile-lg) {
    margin-bottom: 0.5rem;
  }
}
