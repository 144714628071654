@import '../../styles/local-includes.scss';

.contentbox {
  background-color: $white;
  padding: 20px;

  &.shadow {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 4px 8px rgba(0, 0, 0, 0.04);
  }
}

.sectiontitle {
  display: block;
  width: 100%;
  margin: 0 0 30px 0;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 1px solid $black;
}

.hr {
  background-color: $mediumgrey;
  border: 0;
  height: 1px;
  margin-bottom: 30px;
  margin-left: -30px;
  margin-top: 30px;
  width: calc(100% + 60px);
}

.backlink {
  display: flex;
  align-items: center;
  color: inherit;
  font-weight: 500;

  .icon {
    margin-right: 5px;
    height: 18px;
  }
}

.form__divider {
  border-color: $lightgrey;
}

.standard__layout {
  background-color: $white;
  display: flex;
  flex: 1;
  // media queries
  @include breakpoint($tablet-md) {
    flex-direction: column;
  }
}

.standard__layout__main {
  flex-basis: 70%;
  border-right: 1px solid $lightgrey;
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  background-color: $white;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  max-width: 1440px;
  padding: $contentMargin;
  padding-bottom: 0;
  // media queries
  @include breakpoint($tablet-md) {
    padding: $contentPaddingMobile;
    overflow: visible;
    max-height: none;
    border-right: 0;
  }

  &.hide-aside {
    min-width: 100%;
  }

  p {
    color: $darkgrey;
  }

  .input__checkbox {
    margin-bottom: 0;
  }
}

.standard__layout__aside {
  padding: 24px;
  flex-basis: 382px;
  flex-grow: 0;
  flex-shrink: 0;
  flex: 1;
}

.sidenav__inner > ul {
  position: relative;
}
.sidenav__inner > ul:nth-of-type(1) > .menuitem:nth-of-type(1) {
  margin-top: 72px;
}
.sidenav__inner > ul:nth-of-type(1) > .menuitem:nth-of-type(3) {
  position: absolute;
  background-color: #1954eb;
  color: #fff;
  width: 100%;
  font-weight: 500;
  border-radius: 16px;
  top: 63px;
  min-width: 48px;
  height: 56px;
}
.sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(3)
  > .menuitem__label:hover {
  background-color: #0034bb;
  color: #fff !important;
  border-radius: 16px;
}
.sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(3)
  > .menuitem__label {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sidenav__inner > ul > button:nth-of-type(2) {
  display: none;
}

.sidenav.is-collapsed.is-hovering
  .sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(1) {
  margin-top: 72px;
}

.sidenav.is-collapsed .spr_sidenav__inner > ul > .menuitem:nth-of-type(3) {
  margin-left: -13px;
  top: 59px;
}

.sidenav.is-collapsed.is-hovering
  .spr_sidenav__inner
  > ul
  > .menuitem:nth-of-type(3) {
  margin-left: 0px;
  top: 62px;
}

.sidenav.is-collapsed:not(.is-hovering)
  .spr_sidenav__inner
  > ul
  .spr_menuitem:nth-of-type(3)
  .spr_menuitem__label {
  span:nth-child(2) {
    display: none;
  }
  svg {
    margin-right: 0px;
  }
}

/* adjusting copy */
.sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(3)
  > a.menuitem__label
  > span:not(.icon):after {
  content: ' product';
}
/* legacy fixes */
.sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(3)
  > .menuitem__label {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sidenav__inner
  > ul:nth-of-type(1)
  > .menuitem:nth-of-type(3)
  > .menuitem__label
  > span:nth-of-type(3) {
  width: auto;
}
.dashboard__nav .menuitem__label {
  padding: 14px 0;
}
.mobilenav__drawer .menuitem__label {
  padding: 10px 0;
}
/* mobile fixes */
.sidenav.is-mobile ul:not(.menuitem__submenu) .menuitem:nth-of-type(3) a {
  padding: 15px 0;
}
.sidenav.is-mobile
  ul:not(.menuitem__submenu):nth-of-type(1)
  .menuitem:nth-of-type(3) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sidenav.is-mobile > ul:nth-of-type(1) > .menuitem:nth-of-type(3) a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.dashboard__nav .menuitem__label {
  padding: 14px 0 !important;
}
.mobilenav__wrapper .sidenav__inner > ul > .menuitem:nth-of-type(3) .icon {
  display: initial;
}
