.integrations_page_disconnect_button {
  background-color: #fce6e6 !important;
  border-color: #fce6e6 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.01em;
  /* Semantic / Negative */
  color: #df0403 !important;
  border-radius: 16px;
}
