@import '../../../../styles/local-includes.scss';

.collection__productitem {
  display: grid;
  grid-template-columns: 1fr 16.8px;
  align-items: center;
  background-color: $offwhite;
  margin-bottom: 2px;
  border-radius: 2px;
  padding: 12px 30px 12px 60px;
  border: none;
  position: relative;

  .input__checkbox {
    margin-bottom: 0;

    input {
      margin-right: 0;
    }
  }

  span {
    font-weight: 400;
    color: $darkgrey;
  }
}

.collection__productitem__toggle {
  align-items: center;
  display: grid;
  grid-template-columns: minmax(300px, 2fr) 4fr;
  cursor: pointer;
  // media queries
  @include breakpoint($mobile-lg) {
    grid-template-columns: 1fr;
  }
}

.spr_draggable__cellcontent .collection__productitem {
  background-color: $white;
  margin-bottom: 0;
  padding: 0;
  box-shadow: none;
  border-bottom: none;
  &:after {
    display: none;
  }
}

.collection__productitem__title {
  display: flex;
  align-items: center;
  padding-right: 20px;
  position: relative;

  h3 {
    font-size: 14px;
    font-weight: 500;
  }
}

.collection__productitem__icon {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  cursor: pointer;
}

.collection__productitem__thumb {
  background-color: $lightergrey;
  margin-right: 25px;

  img {
    object-fit: contain;
    width: 40px;
    height: 40px;
  }
}

.collection__accordion-child {
  background-color: $lightergrey;
}
