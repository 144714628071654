@import '../../styles/local-includes.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.firstBanner {
  animation: fadeIn 2s;
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $contentMargin;
  max-width: 1440px;
  margin: auto;
  // media queries
  @include breakpoint($tablet-lg) {
    flex-direction: column;
  }
  @include breakpoint($mobile-lg) {
    padding: $contentPaddingMobile;
  }
}

.overview__aside {
  flex: 3;
  margin-left: 30px;
  // media queries
  @include breakpoint($tablet-lg) {
    flex: 1;
    margin-left: 0;
  }
}

.fifthBanner {
  margin-top: 32px;
}

.overview_cont {
  max-width: 1440px;
  margin: auto;
  margin-bottom: 24px;
  overflow: auto;
}
.overview__content {
  max-width: 1440px;
  width: 100%;
  // media queries
  @include breakpoint($tablet-lg) {
    flex: 1;
    margin-bottom: 32px;

    .spr_modal__inner {
      padding: 0 !important;
    }
  }

  .spr_modal__content {
    padding: 0 !important;

    @include breakpoint($mobile-lg) {
      max-width: unset;
    }
  }
}
