@import '../../../styles/local-includes.scss';

.promo_banner {
  width: 100%;

  .promo_banner__image {
    width: 100%;
    border-radius: 24px;
    object-fit: cover;
    margin-bottom: 32px;
  }
}
