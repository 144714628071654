@import '../../styles/local-includes.scss';

.page__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  @include breakpoint($mobile-lg) {
    margin-left: 16px;
    margin-right: 16px;
  }

  svg {
    color: $darkgrey;
  }

  h2 {
    font-size: 24px;
    color: $black;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
  }

  .page__error__body {
    font-size: 14px;
    text-align: center;
    color: $darkergrey;
    padding-top: 15px;
    padding-bottom: 24px;
    max-width: 400px;
  }
}
